.card-register {
  // background-color: #000000;
  border-radius: 8px;
  color: #fff;
  max-width: 550px;
  margin: 20px 0 70px;
  min-height: 400px;
  padding: 30px;
  height: 100%;
  background-image: url('../../../image/ring.jpg');
  background-repeat: no-repeat;
  background-position: center; 
  position: relative;

  & label {
    margin-top: 15px;
  }
  & .card-title {
    color: #B33C12;
    text-align: center;
  }
  & .btn {
    margin-top: 30px;
  }
  & .forgot {
    text-align: center;
  }

  .input-group-text {
    border: 1px solid $white-color;
  }
}
